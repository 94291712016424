.tutoring-page-content {
    padding-top: 40px;
}

.tutoring-page-content {
    display: flex;
    padding: 30px;
}

.tutoring-page-title {
    color: #4daeda;
    margin-bottom: 30px;
    text-align: center;
    padding-top: 40px;
}

.form-title{
    font-size: 23px;
    line-height: 1.8;
    font-weight: 600;
    color: #4c626c;

}

.tutoring-page-content-right{
    padding-right: 20%;
    padding-left: 30px;
}

.midle-line{
    width: 1px;
    margin-bottom: 30px;
    background-color: #5bcbffb8;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
}
