.job-list {
  padding: 1px 60px 60px;
  background-color: #edf9ff;
}

.job {
  margin: 17px;
  padding: 0 15px;
  background-color: rgba(239, 239, 239, 0.436);
  border-radius: 28px;
  justify-content: space-between;
  box-shadow: 0 8px 48px rgba(36, 36, 59, 0.4);
  text-decoration: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

}

.jobTitleBox {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
}

.jobTitle {
  font-size: 30px;
  font-weight: 400;
  padding: 0 25px;
  text-align: center;
  color: #4daeda;
}

.jobFieldsBox {
  padding: 4px 10px;
  max-height: 60%;
  overflow: hidden;
}

.descriptionJobFieldsBox {
  padding: 4px 40px;
  max-height: 60%;
  overflow: hidden;
}

.jobField {
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  line-height: 1;
}

.jobDescription {
  padding-left: 10px;
  font-size: 20px;
  font-weight: 300;
  text-align: initial;
  margin-bottom: 15px;
}

.jobDescriptionText {
  overflow: hidden;
  color: rgba(19, 19, 68, 0.708);
}

.readMore {
  position: absolute;
  bottom: 55px;
  right: 20px;
  cursor: pointer;
  color: rgba(19, 19, 68, 0.5);
}

.MuiPaper-root {
  max-height: 60vh !important;
  text-align: center !important;
  border-radius: 28px !important;
  padding: 20px 0;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.MuiPaper-root::-webkit-scrollbar {
  display: none;
}

.fileName {
  margin: 10px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(19, 19, 68, 0.708);
}

.MuiDialog-paperWidthSm {
  max-width: 80vw !important;
}

.jobDescriptionDialog {
  text-align: center;
  height: 90vh;
  width: 80vw !important;
}

.jobDescriptionTitle {
  color: #4daeda;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  padding: 10px 25px 30px 25px;
}

.jobFieldTitle {
  color: #4daeda;
  font-size: 25px;
  font-weight: 300;
}

.jobFieldText {
  color: rgba(19, 19, 68, 0.708);
}

.jobDescriptionChTitle {
  color: rgba(19, 19, 68, 0.5);
  margin-bottom: 0;
  font-weight: 300;
  color: #4daeda;
  font-size: 25px;
}

.jobDescriptionChText {
  color: rgba(19, 19, 68, 0.708);
}

.applyForJobButtonBox {
  width: 100%;

  bottom: 10px;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin: 10px auto;
}

.submitBox {
  display: flex;
  margin-top: 20px;
}

.paginationButton {
  margin: 0 20px !important;
  background-color: #4daeda !important;
}

.locationSelectTop {
  background-color: #edf9ff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.locationSelect {
  width: 100%;
  height: 120px;
  margin: 17px;
  padding: 0 10px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(239, 239, 239, 0.436);
  box-shadow: 0 0 30px rgba(36, 36, 59, 0.4);
}

.formControl {
  min-width: 240px !important;
}

.jobSelectItem {
  color: #4daeda;
  font-size: 20px;
  font-weight: 200;
}

.locationSelectText {
  color: rgba(19, 19, 68, 0.708);
  margin-left: 20px;
}

.jobCounter {
  font-size: 45px;
  font-weight: 200;
  color: #4daeda;
  text-align: center;
  margin-bottom: 0;
}

.jobCounterText {
  font-size: 0.75rem;
  margin-top: 3px;
  margin-bottom: 33px;
}

.paginationButtons {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  background-color: #edf9ff;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
  border-color: #4daeda !important;
}

.dialogButtonsBox {
  padding-top: 20px;
  margin-bottom: 25px;
}
