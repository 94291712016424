html {
  box-sizing: border-box;
  max-width: 100%;
}
body {
  margin: 0;
  font-family:Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: Arial, sans-serif;
}
.service-banner{
  background-size: cover;
  width: 100%;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

  