.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    color: rgba(255, 255, 255, 0.7);
}

.footer-item {
    font-size: 18px;
    line-height: 1.8;
    flex: 1 1 300px;
    max-width: 400px;
    text-align: center;
}

.descriptionTitle{
    font-size: 19px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.892);
}

.footer-logo-box {
    display: flex;
    justify-content: center;
}

.footer-logo {
    height: 90%;
    width: 90%;
    object-fit: fill;
}

.phone-email {
    text-decoration: none;
    color: #67bde4;
    transition: transform 0.3s, background-color 0.3s;
}
.phone-email:hover {
    color: #0996d7;
    transform: scale(1.2);
}

.footer-email{
    word-break: break-word;
}

.icon-box {
    margin-right: 16px;
}

.text-left {
    text-align: left;
}

.footer h4 {
    margin-bottom: 30px;
    font-size: 25px;
    font-weight: 600;
    color: white
}

.social-button {
    width: 60px;
    height: 60px;
    border-radius: 43px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    transition: transform 0.3s, background-color 0.3s;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.social-button:hover {
    transform: scale(1.2);
    background-color: rgba(255, 255, 255, 0.163);
}
.left-text{
    text-align: left;
}

@media screen and (max-width: 768px) {
    .footer-item {
        flex: 1 1 100%;
        max-width: 100%;
    }
}
  