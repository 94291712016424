.about-container {
    padding: 2rem;
    
    line-height: 1.6;
    color: #333;
    background-color: white;
  }
  
  .about-us {
    margin-bottom: 3rem;
  }
  
  .about-us h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #2980B9;
  }
  
  .about-us p {
    margin-bottom: 1rem;
  }
  
  .leadership-team h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #2980B9;
  }
  
  .leader {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: #f8f8f8;
    border-left: 4px solid #2980B9;
    border-radius: 5px;
  }
  
  .leader-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1.5rem;
  }
  
  .leader-info {
    flex: 1;
  }
  
  .leader-info h3 {
    font-size: 1.2rem;
    color: #2980B9;
    margin-bottom: 0.5rem;
  }
  
  .leader-info h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #222;
  }
  
  .leader-info p {
    margin-bottom: 1rem;
  }
   /* mobile view */
   @media (max-width: 768px) {
    .leader {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
  