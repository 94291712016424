/* Main container styling */
.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Wrapper for image and text */
  .image-wrapper {
    position: relative;
    max-width: 100%;
    width: 400px; /* Adjust this to suit your needs */
  }
  
  /* Image styling with responsive behavior */
  .main-picture {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px; /* Optional: rounded corners */
  }
  
  /* Submit text overlay */
  .submit-text {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    font-size: 1rem;
    white-space: nowrap;
    border-radius: 4px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 600px) {
    .submit-text {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  
    .image-wrapper {
      width: 100%;
    }
  }
  