.assessment-feedback {
    font-family: Arial, sans-serif;
    padding: 10px;
    color: #333;
    background-color: white;
  }
  
  .heading {
    font-size: 2rem;
    color: #2980b9;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .intro {
    margin-bottom: 30px;
  }
  
  .intro p {
    line-height: 1.6;
  }
  
  .services {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .service {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
  }
  
  .service h2 {
    font-size: 1.5rem;
    color: #2980b9;
    margin-bottom: 10px;
  }
  
  .service ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .service ul li {
    margin-bottom: 8px;
  }
  
  .commitment {
    font-style: italic;
    color: #2980b9;
    text-align: center;
    margin-top: 40px;
  }
  