.main-picture-box {
    width: 100vw;
    height: 100vh;
}

.main-picture {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
}
  
.home-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
  }
  
  .home-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
 
  }
  
  .home-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  }
  
  .home-card-header {
    border-radius: 12px;
    padding: 10px;
    color: #fff;
    text-align: center;
  }
  
  .card-title {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
  }
  
  .home-card-body {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-image {
    width: 100%;
    height: 200px;  /* Fixed height for all images */
    object-fit: cover; /* Ensures images cover the space without stretching */
    border-radius: 8px;
  }
  
  .card-nav-link {
    text-decoration: none;
    display: block;
  }

.main-picture-box {
    position: relative;
    width: 100%;
   
  }
  
  .image-overlay-container {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  
  .main-picture {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .image-text-overlay {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #6e8ec6; /* White text */
    font-family: Arial, sans-serif;;
  }
  
  .image-text-overlay h4 {
    font-size: 26px;
    font-weight: bold; /* Bold text */
    margin-bottom: 10px;
  }
  
  .image-text-overlay p {
    font-size: 26px;
    line-height: 1.5;
    font-weight: bold; /* Bold paragraph text */
  }
  
  .bannerImage {
    height: 600px !important;
  }
  .w400{
    width: 100%;
  }
  /* mobile responsive for .image-text-overlay fonts small  */
  @media (max-width: 768px) {
    .image-text-overlay h4 {
      font-size: 20px;
    }
    .image-text-overlay p {
      font-size: 20px;
      padding: 5px;
    }
  } 