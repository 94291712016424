
.candidateJurneyContentBox {
    padding-top: 60px;
}

.candidateJurneyContent {
    padding: 0 40px;
}

.candidateJurneyContentItem {
    height: fit-content;
    display: flex;
    justify-content: space-around;
    margin-bottom: 66px;
}

.candidateJurneyContentItemPicBox {
    width: 45%;
}

.candidateJurneyContentItemPic {
    height: 250px;
    width: 100%;
    object-fit: cover;
}

.candidateJurneyContentItemTextBox {
    width: 45%;
    overflow: hidden;
}

.candidateJurneyContentItemText {
    color: #4c626c;
}
