.page-content {
    padding: 30px;
}

.page-title {
    color: #4daeda;
    margin-bottom: 30px;
    text-align: center;
}

.page-content-text {
    font-size: 18px;
    line-height: 1.8;
    font-weight: 600;
    color: #4c626c;
    padding: 0 30px;
}

.apply-study-button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
}