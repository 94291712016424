.visasAndPermits {
    cursor: default;
}

.page-content-text-primary {
    font-size: 18px;
    line-height: 1.8;
    font-weight: 600;
    color: #4c626c;
    padding: 0 30px;
    text-align: left;
    
}

.chapter-content-text {
    font-size: 18px;
    font-weight: 500;
    padding: 20px 20px 0; 
    color: #4c626c;
}
.chapter-content-text1 {
    font-size: 18px;
    font-weight: 500;
    color: #4c626c;
}

.chapter-title {
    color: #4daeda;
    margin-bottom: 20px;
}

.chapter-title-secondary {
    margin-bottom: 20px;
    font-size: 25px;
    font-style: italic;
    font-weight: 500;
    text-decoration: none;
}

.italic {
    font-style: italic;
    font-weight: 700;
    color: #4497be;
    text-decoration: none;
}

.separateLine {
    height: 1px;
    margin-bottom: 30px;
    background-color: #5bcbffb8;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
}