.school-partnerships {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
    padding: 10px;
    background-color: white;
 
  }
  
  .school-partnerships h1 {
    font-size: 2.5rem;
    color: #2980b9;
    text-align: left;
   
  }
  
  .school-partnerships h2 {
    font-size: 2rem;
    color: #2980b9;
    margin-bottom: 15px;
  }
  
  .school-partnerships p {
    font-size: 1.1rem;
    color: #34495e;
    line-height: 1.6;

    text-align: justify;
  }
  
  .school-partnerships section {
  
    padding: 15px;
    background-color: #ecf0f1;
    border-radius: 8px;
   
  }
  
  .school-partnerships section:last-child {
    margin-bottom: 0;
  }
  
  .school-partnerships .partners,
  .school-partnerships .feasibility-design,
  .school-partnerships .planning-benchmarking,
  .school-partnerships .resources-financials {
    background-color: #ffffff;
  }
  
  .school-partnerships section h2 {
   
  }
  
  .school-partnerships section p {
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .school-partnerships h1 {
      font-size: 2rem;
    }
  
    .school-partnerships h2 {
      font-size: 1.5rem;
    }
  
    .school-partnerships p {
      font-size: 1rem;
    }
  
    .school-partnerships {
      padding: 10px;
    }
  }

